// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-forum-verkaufen-js": () => import("./../../../src/pages/forum-verkaufen.js" /* webpackChunkName: "component---src-pages-forum-verkaufen-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-notice-js": () => import("./../../../src/pages/legal-notice.js" /* webpackChunkName: "component---src-pages-legal-notice-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-werben-js": () => import("./../../../src/pages/werben.js" /* webpackChunkName: "component---src-pages-werben-js" */),
  "component---src-templates-license-index-jsx": () => import("./../../../src/templates/license/index.jsx" /* webpackChunkName: "component---src-templates-license-index-jsx" */)
}

